import { sessionFactory } from '@wise/wise-session';

let session = null;
export function init(clientId) {
  if (!clientId) {
    throw new Error('No clientid given to session storage initialization');
  }

  session = sessionFactory(clientId);
}

/**
 * Set item in session storage
 *
 * @param {String} key Key of item in key/value session storage
 * @param {*} value Value of item to store in session
 * @returns {Boolean} If item is set in session storage
 */
export function setSessionItem(key, value) {
  if (!session) {
    throw new Error('Session not initialized');
  }

  return session.setItem(key, value);
}

/**
 * Get item from session storage
 *
 * @param {String} key Key of item in key/value session storage
 * @param {*} [defaultValue] Default value if key is not available in session key/value storage
 * @returns {*} Value that is stored in key/value session storge
 */
export function getSessionItem(key, defaultValue) {
  if (!session) {
    throw new Error('Session not initialized');
  }

  const content = session.getItem(key, defaultValue);

  try {
    return JSON.parse(content);
  } catch (error) {
    // noop
  }

  return content;
}

/**
 * Remove item from key/value session storage
 *
 * @param {String} key Key of item in key/value session storage
 * @returns {void} Nothing
 */
export function removeSessionItem(key) {
  if (!session) {
    throw new Error('Session not initialized');
  }

  if (Array.isArray(key)) {
    key.forEach((item) => session.removeItem(item));
  }

  return session.removeItem(key);
}

/**
 * Clear key/value session storage.
 *
 * @returns {void} Nothing
 */
export function clearSession() {
  if (!session) {
    throw new Error('Session not initialized');
  }

  return session.clear();
}
