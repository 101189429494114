function fixHashOrder(location, whitelistUrlParts) {
  const hash = location.hash.split('&');

  if (hash.length <= 1) {
    return location;
  }

  if (!whitelistUrlParts.some((item) => location.href.indexOf(item) >= 0)) {
    return location;
  }

  const newHash = hash[0];
  const searchArray = hash.slice(1);
  if (location.search) {
    searchArray.unshift(location.search.substring(1));
  }
  const newSearch = searchArray.join('&');

  return {
    protocol: location.protocol,
    host: location.host,
    pathname: location.pathname,
    search: `?${newSearch}`,
    hash: newHash,
  };
}

// This replace a base64 encoded search string by its decoded search string
function fixBase64Search(location) {
  try {
    const search64 = location.search.substring(1);
    if (search64) {
      const search = window.atob(window.decodeURIComponent(search64));

      // tk= is part of token search string, so if this is decoded in
      // we have a base64 search string here
      if (search.indexOf('tk=') >= 0) {
        return {
          protocol: location.protocol,
          host: location.host,
          pathname: location.pathname,
          search: `?${search}`,
          hash: location.hash,
        };
      }
    }
  } catch (error) {
    // do nothing
  }

  return location;
}

function generateLocationString(location) {
  return `${location.protocol}//${location.host}${location.pathname}${location.search}${location.hash}`;
}

export default function fixUrl(whitelistUrlParts = []) {
  let newLocation = fixHashOrder(window.location, whitelistUrlParts);
  newLocation = fixBase64Search(newLocation);

  const newLocationHref = generateLocationString(newLocation);
  if (newLocationHref !== window.location.href) {
    window.location.replace(newLocationHref);
  }
}
