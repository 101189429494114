/* global module */

import React from 'react';
import { render } from 'react-dom';
import Component from './main'; // eslint-disable-line

if (module.hot) {
  module.hot.accept();
}

render(<Component />, document.getElementById('app'));
