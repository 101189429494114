import get from 'lodash/get';
import set from 'lodash/set';

let configurationStore = {};

export function getConfiguration(item, defaultValue = undefined) {
  return get(configurationStore, item, defaultValue);
}

export function setConfiguration(configuration) {
  configurationStore = configuration;
}

export function setConfigurationValue(key, value) {
  set(configurationStore, key, value);
}

export const isV2Journey = () => getConfiguration('Journey_V2');
