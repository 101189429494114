import { getConfiguration } from './configuration';

export function isTrusteeFlow() {
  return (
    getConfiguration('hasTrusteeLogin') ||
    window.location.href.indexOf('trustee') > -1
  );
}

export function isStaticPage() {
  return window.location.hash.indexOf('trusteeinstructions') > -1;
}
